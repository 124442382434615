ul,
#myUL-treeview {
    list-style-type: none;
}

#myUL-treeview {
    margin: 0;
    padding: 0;
}

.caret-treeview {
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari 3.1+ */
    -moz-user-select: none;
    /* Firefox 2+ */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
}

.chevron-icon {
    color: black;
    display: inline-block;
    margin-right: 6px;
}

.chevron-icon-down {
    -ms-transform: rotate(90deg);
    /* IE 9 */
    -webkit-transform: rotate(90deg);
    /* Safari */
    transform: rotate(90deg);
}

.nested-treeview {
    display: none;
}

.active-treeview {
    display: block;
}

.option-treeview {
    width: fit-content;
    background-color: rgba(0, 174, 239, 0);
    transition: background-color 0.2s ease-in-out;
}

.option-treeview:hover {
    background-color: rgba(0, 174, 239, 0.3);
}

.tree-view-option-selected {
    background-color: rgba(0, 174, 239, 0.3);
}
